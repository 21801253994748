/**
* Template Name: WeBuild
* Template URL: https://bootstrapmade.com/free-bootstrap-coming-soon-template-countdwon/
* Updated: Aug 08 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# Font & Color Variables
# Help: https://bootstrapmade.com/color-system/
--------------------------------------------------------------*/
/* Fonts */
:root {
    --default-font: "Roboto", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --heading-font: "Raleway", sans-serif;
    --nav-font: "Poppins", sans-serif;
}

/* Global Colors - The following color variables are used throughout the website. Updating them here will change the color scheme of the entire website */
:root {
    --background-color: #ffffff;
    /* Background color for the entire website, including individual sections */
    --default-color: #ffffff;
    /* Default color used for the majority of the text content across the entire website */
    --heading-color: #ffffff;
    /* Color for headings, subheadings and title throughout the website */
    --accent-color: #4f92af;
    /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
    --surface-color: #ffffff;
    /* The surface color is used as a background of boxed elements within sections, such as cards, icon boxes, or other elements that require a visual separation from the global background. */
    --contrast-color: #ffffff;
    /* Contrast color for text, ensuring readability against backgrounds of accent, heading, or default colors. */
}

/* Nav Menu Colors - The following color variables are used specifically for the navigation menu. They are separate from the global colors to allow for more customization options */
:root {
    --nav-color: #ffffff;
    /* The default color of the main navmenu links */
    --nav-hover-color: #4f92af;
    /* Applied to main navmenu links when they are hovered over or active */
    --nav-mobile-background-color: rgba(6, 6, 6, 0.55);
    /* Used as the background color for mobile navigation menu */
    --nav-dropdown-background-color: rgba(6, 6, 6, 0.55);
    /* Used as the background color for dropdown items that appear when hovering over primary navigation items */
    --nav-dropdown-color: #ffffff;
    /* Used for navigation links of the dropdown items in the navigation menu. */
    --nav-dropdown-hover-color: #4f92af;
    /* Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
}

/* Color Presets - These classes override global colors when applied to any section or element, providing reuse of the sam color scheme. */

.light-background {
    --background-color: #f9f9f9;
    --surface-color: #ffffff;
}

.dark-background {
    --background-color: #060606;
    --default-color: #ffffff;
    --heading-color: #ffffff;
    --surface-color: #252525;
    --contrast-color: #ffffff;
}

.transparent-background {
    --background-color: rgba(0, 0, 0, 0.275);
    --surface-color: rgba(255, 255, 255, 0.3);
}

/* Smooth scroll */
:root {
    scroll-behavior: smooth;
}

/*--------------------------------------------------------------
  # General Styling & Shared Classes
  --------------------------------------------------------------*/
body {
    color: var(--default-color);
    background-color: var(--background-color);
    font-family: var(--default-font);
    background: url(../../Assets/bg.jpg) top center no-repeat;
    background-size: cover;
    position: relative;
}

@media (min-width: 1366px) {
    body {
        background-attachment: fixed;
    }
}

a {
    color: var(--accent-color);
    text-decoration: none;
    transition: 0.3s;
}

a:hover {
    color: color-mix(in srgb, var(--accent-color), transparent 25%);
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--heading-color);
    font-family: var(--heading-font);
}

/* PHP Email Form Messages
  ------------------------------*/
.php-email-form .error-message {
    display: none;
    background: #df1529;
    color: #ffffff;
    text-align: left;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
}

.php-email-form .sent-message {
    display: none;
    color: #ffffff;
    background: #059652;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
}

.php-email-form .loading {
    display: none;
    background: var(--surface-color);
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
}

.php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid var(--accent-color);
    border-top-color: var(--surface-color);
    animation: php-email-form-loading 1s linear infinite;
}

@keyframes php-email-form-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
  # Global Header
  --------------------------------------------------------------*/

/*--------------------------------------------------------------
  # Global Footer
  --------------------------------------------------------------*/
.footer {
    color: var(--default-color);
    background-color: var(--background-color);
    font-size: 14px;
    padding: 40px 0;
    position: relative;
}

.footer .copyright p {
    margin-bottom: 0;
}

.footer .social-links {
    margin-top: 20px;
}

.footer .social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 50%);
    font-size: 16px;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    margin: 0 5px;
    transition: 0.3s;
}

.footer .social-links a:hover {
    color: var(--accent-color);
    border-color: var(--accent-color);
}

.footer .credits {
    margin-top: 10px;
    font-size: 13px;
    text-align: center;
}

/*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
#preloader {
    position: fixed;
    inset: 0;
    z-index: 999999;
    overflow: hidden;
    background: var(--background-color);
    transition: all 0.6s ease-out;
}

#preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #ffffff;
    border-color: var(--accent-color) transparent var(--accent-color) transparent;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: animate-preloader 1.5s linear infinite;
}

@keyframes animate-preloader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
  # Scroll Top Button
  --------------------------------------------------------------*/
.scroll-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background-color: var(--accent-color);
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
}

.scroll-top i {
    font-size: 24px;
    color: var(--contrast-color);
    line-height: 0;
}

.scroll-top:hover {
    background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
    color: var(--contrast-color);
}

.scroll-top.active {
    visibility: visible;
    opacity: 1;
}

/*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
    [data-aos-delay] {
        transition-delay: 0 !important;
    }
}

/*--------------------------------------------------------------
  # Global Page Titles & Breadcrumbs
  --------------------------------------------------------------*/
.page-title {
    color: var(--default-color);
    background-color: var(--background-color);
    position: relative;
}

.page-title .heading {
    padding: 80px 0;
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.page-title .heading h1 {
    font-size: 38px;
    font-weight: 700;
}

.page-title nav {
    background-color: color-mix(in srgb, var(--default-color), transparent 95%);
    padding: 20px 0;
}

.page-title nav ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.page-title nav ol li+li {
    padding-left: 10px;
}

.page-title nav ol li+li::before {
    content: "/";
    display: inline-block;
    padding-right: 10px;
    color: color-mix(in srgb, var(--default-color), transparent 70%);
}

/*--------------------------------------------------------------
  # Global Sections
  --------------------------------------------------------------*/
section,
.section {
    color: var(--default-color);
    background-color: var(--background-color);
    padding: 60px 0;
    scroll-margin-top: 90px;
    overflow: clip;
}

@media (max-width: 1199px) {

    section,
    .section {
        scroll-margin-top: 66px;
    }
}

/*--------------------------------------------------------------
  # Global Section Titles
  --------------------------------------------------------------*/
.section-title {
    text-align: center;
    padding-bottom: 60px;
    position: relative;
}

.section-title h2 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 15px;
}

.section-title p {
    margin-bottom: 0;
}

/*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
.hero {
    width: 100%;
    min-height: 100vh;
    position: relative;
    padding: 80px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero h2 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
}

.hero p {
    margin: 10px 0 0 0;
    font-size: 24px;
    color: var(--heading-color);
}

@media (max-width: 768px) {
    .hero h2 {
        font-size: 32px;
    }

    .hero p {
        font-size: 18px;
    }
}

.hero .countdown {
    margin-top: 25px;
    font-size: 42px;
}

.hero .countdown span {
    font-size: 48px;
    font-weight: 700;
}

.hero .hero-newsletter {
    margin-top: 25px;
}

.hero .hero-newsletter p {
    font-size: 18px;
    margin: 0 0 15px 0;
}

.hero .hero-newsletter .newsletter-form {
    margin-bottom: 15px;
    position: relative;
    display: flex;
    border-radius: 0px;
}

.hero .hero-newsletter .newsletter-form:focus-within {
    border-color: var(--accent-color);
}

.hero .hero-newsletter .newsletter-form input[type=email] {
    padding: 8px 10px;
    width: 100%;
    background-color: var(--surface-color);
    color: var(--default-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
    transition: 0.3s;
}

.hero .hero-newsletter .newsletter-form input[type=email]:focus {
    border-color: var(--accent-color);
}

.hero .hero-newsletter .newsletter-form input[type=email]:focus-visible {
    outline: none;
}

.hero .hero-newsletter .newsletter-form input[type=submit] {
    border: 0;
    font-size: 16px;
    padding: 0 20px;
    margin: 0 0 0 10px;
    background: var(--accent-color);
    color: var(--contrast-color);
    transition: 0.3s;
    border-radius: 0px;
}

.hero .hero-newsletter .newsletter-form input[type=submit]:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

/*--------------------------------------------------------------
  # About Section
  --------------------------------------------------------------*/
.about .content h3 {
    font-size: 2rem;
    font-weight: 700;
}

.about .content p {
    margin: 30px 0;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.about .content ul {
    list-style: none;
    padding: 0;
}

.about .content ul li {
    padding: 0 0 15px 0;
    font-weight: 600;
    display: flex;
}

.about .content ul i {
    color: var(--accent-color);
    font-size: 1.25rem;
    line-height: 1.2;
    margin-right: 0.5rem;
}

.about .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.about .row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about .content {
    text-align: center;
}


/*--------------------------------------------------------------
  # Contact Section
  --------------------------------------------------------------*/
.contact {
    background-position: left center;
    background-repeat: no-repeat;
    position: relative;
}

@media (max-width: 640px) {
    .contact {
        background-position: center 50px;
        background-size: contain;
    }
}

.contact:before {
    content: "";
    background: color-mix(in srgb, var(--background-color), transparent 30%);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

.contact .info-item+.info-item {
    margin-top: 40px;
}

.contact .info-item i {
    background: var(--accent-color);
    color: var(--contrast-color);
    font-size: 20px;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    margin-right: 15px;
}

.contact .info-item h3 {
    padding: 0;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
}

.contact .info-item p {
    padding: 0;
    margin-bottom: 0;
    font-size: 14px;
}

.contact .php-email-form {
    height: 100%;
}

.contact .php-email-form input[type=text],
.contact .php-email-form input[type=email],
.contact .php-email-form textarea {
    font-size: 14px;
    padding: 10px 15px;
    box-shadow: none;
    border-radius: 0;
    color: var(--default-color);
    background-color: color-mix(in srgb, var(--background-color), transparent 50%);
    border-color: color-mix(in srgb, var(--default-color), transparent 80%);
}

.contact .php-email-form input[type=text]:focus,
.contact .php-email-form input[type=email]:focus,
.contact .php-email-form textarea:focus {
    border-color: var(--accent-color);
}

.contact .php-email-form input[type=text]::placeholder,
.contact .php-email-form input[type=email]::placeholder,
.contact .php-email-form textarea::placeholder {
    color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.contact .php-email-form button[type=submit] {
    color: var(--contrast-color);
    background: var(--accent-color);
    border: 0;
    padding: 10px 30px;
    transition: 0.4s;
    border-radius: 50px;
}

.contact .php-email-form button[type=submit]:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

.searchBox {
    display: flex;
    max-width: 50%;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    background: #2f3640;
    border-radius: 50px;
    position: relative;
}

.searchButton {
    color: white;
    position: absolute;
    right: 8px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--gradient-2, linear-gradient(90deg, #2AF598 0%, #009EFD 100%));
    border: 0;
    display: inline-block;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
}

/*hover effect*/
button:hover {
    color: #fff;
    background-color: #1A1A1A;
    box-shadow: rgba(0, 0, 0, 0.5) 0 10px 20px;
    transform: translateY(-3px);
}

/*button pressing effect*/
button:active {
    box-shadow: none;
    transform: translateY(0);
}

.searchInput {
    border: none;
    background: none;
    outline: none;
    color: white;
    font-size: 15px;
    padding: 24px 46px 24px 26px;
}