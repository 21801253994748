body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.image-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

picture, img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
}
