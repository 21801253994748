.modal-overlay-subs {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content-subs {
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 450px;
    position: relative;
    box-sizing: border-box;
    background: linear-gradient(180deg, #DCF9E0 0%, #FFFFFF 30.21%);
    box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01), 0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09), 0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.modal-subs {
    width: 100%;
    background: linear-gradient(180deg, #DCF9E0 0%, #FFFFFF 30.21%);
    padding: 30px;
}

.form-subs {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.banner-subs {
    width: 100%;
    height: 30px;
    margin: 0;
    background-size: 100%;
    background-repeat: no-repeat;
}

.title-subs {
    font-weight: 700;
    font-size: 22px;
    line-height: 21px;
    text-align: center;
    color: #2B2B2F;
    margin-bottom: 15px;
}

.description-subs {
    max-width: 80%;
    margin: auto;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #5F5D6B;
}

.tab-container-subs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    padding: 2px;
    background-color: #ebebec;
    border-radius: 9px;
    margin: 10px 20px 0px 20px;
}

.indicator-subs {
    content: "";
    width: 50%;
    height: 28px;
    background: #FFFFFF;
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: 9;
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
    border-radius: 7px;
    transition: all 0.2s ease-out;
}

.tab {
    width: 50%;
    height: 28px;
    position: relative;
    z-index: 99;
    background-color: transparent;
    border: 0;
    outline: none;
    flex: none;
    align-self: stretch;
    flex-grow: 1;
    cursor: pointer;
    font-weight: 500;
}

.tab--1:hover~.indicator-subs {
    left: 2px;
}

.tab--2:hover~.indicator-subs {
    left: calc(50% - 2px);
}

.benefits-subs {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.benefits-subs>span {
    font-size: 15px;
    color: #2B2B2F;
    font-weight: 700;
}

.benefits-subs ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.benefits-subs ul li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.benefits-subs ul li span {
    font-weight: 600;
    font-size: 12px;
    color: #5F5D6B;
}

.modal-footer-subs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-top: 1px solid #ebebec;
}

.price-subs {
    position: relative;
    font-size: 32px;
    color: #2B2B2F;
    font-weight: 900;
}

.price-subs sup {
    font-size: 13px;
}

.price-subs sub {
    width: fit-content;
    position: absolute;
    font-size: 11px;
    color: #5F5D6B;
    bottom: 5px;
    display: inline-block;
}

.upgrade-btn-subs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 40px;
    background: #199FD9;
    box-shadow: 0px 0.5px 0.5px #EFEFEF, 0px 1px 0.5px rgba(239, 239, 239, 0.5);
    border-radius: 7px;
    border: 0;
    outline: none;
    color: #ffffff;
    font-size: 13px;
    font-weight: 600;
    transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.upgrade-btn-subs:hover {
    background-color: #2d79f3;
}