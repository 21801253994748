.prdcard {
    padding: 35px 40px 50px;
    margin-bottom: 40px;
    border: none !important;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
}

.prdcard h1 {
    color: black;
    font-weight: 600;
}

.blue-text {
    color: #00BCD4;
}

.form-control-label-ques {
    margin: 15px 0;
}

.text-field-ques {
    padding: 8px 15px;
    border-radius: 5px !important;
    margin: 10px 0px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    font-size: 18px !important;
    font-weight: 300;
}

.text-field-ques:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #00BCD4;
    outline-width: 0;
    font-weight: 400;
}

.btn-primary-ques {
    text-transform: uppercase;
    font-size: 15px !important;
    font-weight: 400;
    height: 43px;
    cursor: pointer;
    background-color: #00BCD4;
    color: #fff;
    border: none;
}

.btn-primary-ques:hover {
    background-color: #00a4c6;
    color: #fff !important;
}

button:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline-width: 0;
}

.home {
    display: flex;
    margin-bottom: 150px;

    .homeContainer {
        width: 80%;
        flex: 6;
        margin: 0 auto;
        /* Centering */
    }
}